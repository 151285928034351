import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import LocalizedLink from "../components/LocalizedLink"

import translations from "../translations"
import buildingImage from "../images/black_building_small.jpg"
import projectManagementImage from "../images/project_management_small.jpg"
import ramImage from "../images/ram_small.jpg"

export const query = graphql`
query IndexPageQuery {
  headerImage: file(relativePath: { eq: "microcontroller.jpg" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(
        maxWidth: 1400
        quality: 90
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  buildingImage: file(relativePath: { eq: "black_building_small.jpg" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(
        maxWidth: 600
        quality: 90
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

const IndexPage = ({ data, pathContext: {locale} }) => (
  <Layout locale={locale}>
    <SEO title="Home" keywords={[`oulongtech`, `dram`, `product development`, `memory test`, `china`, `germany`]} />
    <div className="page-hero" >
      <Img fluid={data.headerImage.childImageSharp.fluid} alt="memory board" style={{height: "100vh"}} />
      <div className="page-hero-container center">
        <h1 className="page-title">{translations[locale].landingPage.headerTitle}</h1>
        <h2 className="page-claim">{translations[locale].landingPage.headerClaim}</h2>
      </div>
      <div className="page-hero-container center">
        <a href="#overview" className="scroll-down">
          <div className="chevron"></div>
          <div className="chevron"></div>
          <div className="chevron"></div>
        </a>
      </div>
    </div>
    <div className="page">
      <div id="overview" className="row teaser-row">
        <a href="/about" className="one-third no-margin teaser">
          <img src={buildingImage} alt="building" />
          <h2>{translations[locale].landingPage.whoWeAre}</h2>
        </a>
        <a href="product-development" className="one-third no-margin teaser">
          <img src={projectManagementImage} alt="Project Management" />
          <h2>{translations[locale].landingPage.productDevelopment}</h2>
        </a>
        <a href="memory-test" className="one-third no-margin teaser">
          <img src={ramImage} alt="ram module" />
          <h2>{translations[locale].landingPage.innovativeMemoryTest}</h2>
        </a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
